@import "./styles/global";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  background: #f1f6f9;
  overflow-x: hidden; 
}
html {
  font-size: 62.5%;
  margin: 0;
  overflow-x: hidden;
  scroll-behavior: smooth;
  height: 100%;
}

.wrapper {
  max-width: 1350px;
  height: 100%;
  margin: 0 auto;
  padding: 0 4.6rem;
}

@include mobileL {
  .wrapper {
    padding: 0 1.6rem !important;
  }
}
