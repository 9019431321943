$mobileS: 323px;
$mobileM: 378px;
$mobileML: 478px;
$mobileL: 630px;
$tablet: 770px;
$tabletL: 995px;
$laptop: 1203px;
$laptopL: 1403px;
$desktop: 2560px;

@mixin mobileS {
  @media (max-width: #{$mobileS}) {
    @content;
  }
}
@mixin mobileM {
  @media (max-width: #{$mobileM}) {
    @content;
  }
}
@mixin mobileML {
  @media (max-width: #{$mobileML}) {
    @content;
  }
}
@mixin mobileL {
  @media (max-width: #{$mobileL}) {
    @content;
  }
}

@mixin laptop {
  @media (max-width: #{$laptop}) {
    @content;
  }
}
@mixin laptopL {
  @media (max-width: #{$laptopL}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}

@mixin tabletL {
  @media (max-width: #{$tabletL}) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: #{$desktopL}) {
    @content;
  }
}