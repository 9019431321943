@import "../../styles/global/devices.scss";
@import "../../styles/global/colors.scss";

.additional-cars-container {
  display: flex;
  min-height: 80vh;
  flex-direction: column;
  width: 90%;
  margin: auto;
  color: #043f69;
  margin-bottom: 80px;

  .additional-cars-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 748px;
    margin: auto;
    margin-bottom: 48px;
    margin-top: 140px;

    @include mobileML {
      margin-top: 80px;
    }

    h2 {
      font-family: "Forma DJR Banner", sans-serif;
      font-weight: 700;
      font-size: 38px;
      line-height: 110%;
      margin-bottom: 16px;
      letter-spacing: 0.02em;

      @include mobileL {
        font-size: 26px !important;
      }
    }
    p {
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      width: 100%;
      text-align: center;

      @include mobileL {
        font-size: 14px !important;
      }
    }
  }

  .additional-cars-wrapper {
    width: 100%;
    padding: 22px 31px 39px;
    margin-bottom: 32px;
    background: #f4f4f4;
    border-radius: 10px;
    position: relative;

    p {
      font-family: "Forma DJR Banner", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 110%;
      letter-spacing: 0.02em;
      padding-bottom: 12px;
      border-bottom: 1px solid #043f69;
    }

    .cross {
      background: #ededed;
      padding: 10px;
      position: absolute;
      top: 23px;
      right: 0px;
      transform: translate(-50%, -50%);
    }

    .additional-cars-main {
      display: flex;

      .car-input-wrapper {
        display: flex;
        gap: 19px;
        width: 100%;
        justify-content: space-evenly;
        font-family: "Forma DJR Banner", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
        letter-spacing: 0.02em;

        @include mobileL {
          flex-direction: column;
        }

        .car-input {
          display: flex;
          flex-direction: column;
          width: 100%;

          label {
            margin: 25px 0px 17px;
          }

          input {
            height: 49px;
            padding-left: 20px;
          }
        }
      }
    }
  }

  .add-car-btn {
    font-family: "Forma DJR Banner", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 110%;
    text-align: center;
    padding: 22px 0px;
    letter-spacing: 0.02em;
    width: 100%;
    background: #e4efff;
    border: none;
    border: 1px solid #259cf4;
    border-radius: 10px;
    cursor: pointer;
    margin-bottom: 64px;
  }

  > #submit-btn {
    width: 284px;
    margin: auto;
  }
}
