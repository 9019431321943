@import "../../styles/global/devices.scss";

.navbar {
  position: absolute;
  width: 100%;
  padding: 10px 0px;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100px;
      height: 100px;

      path{
        fill: #fff;
      }
    }

    .left-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > svg:first-of-type {
        margin-right: 50px;
      }
      svg:nth-of-type(2) {
        > path:nth-of-type(2) {
          fill: #26492d;
        }

        > path:nth-of-type(3) {
          fill: #6de88a;
        }

        > path:nth-of-type(4) {
          fill: #fff;
        }
      }
    }

    &.blue-fill {
      svg {
        path {
          fill: rgb(4, 71, 122);
        }
      }
    }
  }

  @include mobileL {
    padding: 20px 0 40px;

    .wrapper {
      > svg {
        width: 145px;
      }
      .left-icons {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > svg {
          width: 50px !important;

          &:first-of-type {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
