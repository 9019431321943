.data-display {
  color: #043f69;
  background: #eef1f1;
  border: 0.2px solid #03406d;
  border-radius: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  text-align: center;
  height: 50px;
}

.btn-form {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  padding: 15px 50px 12px;
  border: none;
  outline: none;
  border-radius: 100px;
  text-transform: uppercase;
  min-width: 230px;
  cursor: pointer;

  &.primary {
    background: $primary_color;
    border: 2px solid $primary_color;
  }
  &.secondary {
    background: $secondary_color;
    border: 2px solid $secondary_color;
  }
  &.tertiary {
    background: $tertiary_color;
    border: 2px solid $tertiary_color;
  }
  &.ghost {
    background: white;
    border: 2px solid $tertiary_color;
    color: $tertiary_color;
  }
  &.search {
    border: 1px solid #043f69;
    color: #848484;
    background: white;
  }

  @include tabletL {
    font-size: 1.8rem;
  }

  @include mobileML {
    min-width: 150px;
  }
}

.btn-home {
  background: $primary_color;
  padding: 20px 30px;
  border-radius: 88px;
  border: none;
  outline: none;
  font-family: "Forma DJR Banner", sans-serif;
  font-size: 20px;
  font-weight: 500;
  color: white;
  line-height: 122.9%;
  font-style: normal;
  letter-spacing: 0.1em;
  height: fit-content;
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    transform: translateY(-2px);
    -webkit-box-shadow: 5px 5px 16px -6px rgba(0, 0, 0, 0.39);
    box-shadow: 5px 5px 16px -6px rgba(0, 0, 0, 0.39);
  }

  &:active {
    transform: translateY(0);
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  @include tabletL {
    font-size: 16px;
  }

  @include mobileL {
    font-size: 18px;
  }

  @include mobileL {
    width: 100%;
    margin-top: 20px;
  }

  @include mobileML {
    min-width: 150px;
  }
}

.input-number-form {
  background: #eef1f1;
  border: 0.2px solid #03406d;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;

  text-indent: 5rem;

  padding: 1rem 0;

  &::placeholder {
    color: #043f69;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &.telephone-input {
    text-indent: 2rem;
    &::placeholder {
      color: #043f69;
      font-weight: 300;

      @include mobileL {
        font-size: 14px;
        text-indent: 1.4rem;
      }
    }
  }

  &.error {
    border: 1px solid red;
  }
}

.input-text-form{
  color: #043f69;
  background: #eef1f1;
  border: 0.2px solid #03406d;
  border-radius: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3rem;

  text-indent: 2rem;

  padding: 1rem 0;

  &::placeholder {
    color: #043f69 !important;
    font-weight: 300;
  }

  .error{
    border: 1px solid red;
  }

  @include mobileL {
    text-indent: 1.5rem;
  }

  @include mobileML {
    font-size: 14px;
  }
}