@import "../../../../styles/global/devices.scss";

.header-home {
  background: url("../../../../assets/images/bgBlue.png") center center / cover;
  height: 660px;

  @include tablet {
    height: 760px;
  }
  @include mobileL {
    height: auto;
    padding: 100px 0 100px;
  }

  .wrapper {
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .content-left {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .top-content {
          color: white;
          margin-bottom: 20px;
          padding-right: 50px;

          h1 {
            font-size: 60px;
            font-family: "Forma DJR Banner", sans-serif;
            font-weight: 700;
          }
          p {
            font-size: 26px;
            font-weight: 300;
          }
        }
        .form {
          p {
            color: white;
            font-size: 20px;
            font-weight: 300;
            margin-bottom: 5px;

            @include mobileL {
              font-size: 14px;
            }
          }
          .form-vehicule {
            display: flex;
            justify-content: flex-start;

            .wrapper-input {
              border: 3px solid white;
              border-radius: 9px;
              width: fit-content;
              margin-right: 30px;

              .input {
                height: 65px;
                max-width: 303px;
                width: 100%;
                display: flex;
                border: 4px solid rgb(4, 71, 122);
                border-radius: 7px;

                .input-flag {
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  align-items: center;
                  background: rgb(37, 156, 244);
                  height: 100%;
                  width: 30px;
                  position: relative;

                  svg {
                    width: 18px;
                    height: auto;
                    margin-top: 5px;
                  }
                  p {
                    font-family: "Forma DJR Banner", sans-serif;
                    font-weight: 700;
                    font-size: 12px;
                    margin-bottom: 7.5px;
                    color: white;
                  }

                  &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -20px;
                    height: 65%;
                    border-right: 2px solid rgb(30, 30, 30);
                    opacity: 0.2;
                    z-index: 1;
                  }
                }
                input {
                  width: 100%;
                  border: none;
                  outline: none;
                  text-align: center;
                  font-weight: bold;
                  font-family: "Forma DJR Banner", sans-serif;
                  font-size: 24px;
                  color: #161616;
                  text-transform: uppercase;
                  border-radius: 0;
                  &::placeholder {
                    color: #1e1e1e;
                    opacity: 0.2;
                  }
                }
              }
            }
          }

          .wrapper-button {
            margin-bottom: 7px;
          }
        }
      }
    }
    .content-right {
      background: url("../../../../assets/images/woman.png") center bottom /
        contain no-repeat;
      width: 58%;
      height: 100%;
    }

    @include laptop {
      position: relative;

      .content-left {
        width: 80% !important;
      }
      .content-right {
        width: 50%;
        position: absolute;
        right: -18%;
      }
    }
    @include tabletL {
      .content-left {
        width: 90% !important;
      }
    }
    @include tablet {
      .content-left {
        width: 100% !important;

        .form-vehicule {
          flex-direction: column;
          align-items: flex-start;

          > div {
            margin: 10px 0;
          }
        }
      }
      .content-right {
        display: none;
      }
    }

    @include mobileL {
      .content-left {
        > div:first-of-type {
          h1 {
            font-size: 50px !important;
          }
          p {
            font-size: 17px !important;
          }
        }
        .form-vehicule {
          .wrapper-input {
            width: 100% !important;
            .input {
              max-width: 100% !important;
              width: 100% !important;
            }
          }
        }
      }
    }

    @include mobileL {
      .content-left {
        > div:first-of-type {
          h1 {
            font-size: 40px !important;
          }
        }

        > div:last-of-type {
          > div:first-of-type {
            > div:first-of-type {
              margin-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}
