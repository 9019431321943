@import "../../../../styles/global/devices.scss";
@import "../../../../styles/global/colors.scss";

.step-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .step {
    width: -webkit-fill-available;

    display: flex;
    justify-content: center;

    color: black;

    p {
      font-family: "Forma DJR Banner", sans-serif;
      color: #043f69;

      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 110%;
      letter-spacing: 0.02em;
      text-align: center;
      margin-bottom: 25px;
    }

    .step-one {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      max-width: 460px;
      width: 100%;

      > p {
        width: 100%;
        text-align: center;
      }

      input {
        width: 100%;
        margin-bottom: 55px;
      }

      button {
        margin: 0 auto;
      }
    }
    .two-inner {
      display: flex;
      flex-direction: column;

      .two-title {
        font-family: "Forma DJR Banner", sans-serif;
        font-size: 28px;
      }

      .two-subTitle {
        font-size: 16px;
        margin-top: 1px;
        margin-bottom: 10px;
        font-weight: 300;
      }

      .dropdown-wrapper {
        position: relative;
        margin-top: 7px;
        margin-bottom: 43px;

        > img {
          position: absolute;
          right: 15px;
          top: 20px;
        }

        .two-dropdown {
          color: #043f69;
          width: 379px;
          height: 49px;
          background: #eef1f1;
          border: 0.2px solid #03406d;
          border-radius: 4px;
          padding-left: 20px;
          font-size: 16px;

          @include mobileL {
            width: 321px;
          }

          -webkit-appearance: none; /* Safari and Chrome */
          -moz-appearance: none; /* Firefox */
          appearance: none;

          &::placeholder {
            color: gray;
          }

          &:-ms-input-placeholder {
            color: gray;
          }

          &::-ms-input-placeholder {
            color: gray;
          }
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: center;
      }
    }
    .three-inner {
      display: flex;
      flex-direction: column;
      justify-content: center;

      > h2 {
        font-family: "Forma DJR Banner", sans-serif;
        font-size: 28px;
        line-height: 110%;
        font-weight: 500;
        text-align: center;
        letter-spacing: 0.02em;
        color: #043f69;

        @include mobileL {
          font-size: 20px;
        }
      }

      > div {
        margin-top: 53px;
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
    .step-four {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 650px;

      button {
        margin: 0 auto;
      }

      > div {
        max-width: 460px;
        width: 100%;
        margin-bottom: 7.7rem;
        position: relative;
        margin: 0 auto 55px;

        input {
          width: 100%;

          &::placeholder {
            font-weight: 300;
          }
        }

        &:after {
          content: "£";

          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 25px;

          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #043f69;
        }
      }
    }
    .step-five {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 590px;

      &__input {
        max-width: 460px;

        .link-five {
          margin-top: 10px;
          font-size: 12px;
          font-style: italic;
          color: #043f69;
          align-self: flex-start;

          a:visited {
            color: inherit;
            text-decoration: none;
          }
        }
        > div {
          position: relative;
          width: 100%;
          margin-bottom: 7.7rem;
          display: flex;
          flex-direction: column;
          align-items: center;

          input {
            width: 460px;
            &::placeholder {
              color: $primary_color;
              font-weight: 300;
            }

            @include mobileML {
              width: 322px;
            }
          }
        }
        .dropdown-results {
          margin: 0 !important;
          top: 62%;
          width: 100%;
          height: 180px;
          overflow-y: scroll;
          position: absolute;
          background: white;
          z-index: 10;
          border: 1px solid $secondary_color;
          border-radius: 10px;

          li {
            padding: 1rem;
            font-size: 12px;
            border-bottom: 0.5px solid grey;
            cursor: pointer;
          }
        }
      }

      > button {
        margin: 0 auto;
      }
    }

    .step-six {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: auto;

      h1 {
        font-family: "Forma DJR Banner", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 110%;
        color: #043f69;
        letter-spacing: 0.02em;
        text-align: center;
        margin-bottom: 37px;

        @include mobileL {
          font-size: 20px;
          margin-bottom: 26px;
        }
      }

      .six-options-wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        justify-content: center;
      }

      .radio-container {
        input[type="radio"] {
          opacity: 0;
        }

        label {
          font-family: $tertiary_color;
          font-style: normal;
          text-align: center;
          padding: 8px 45px;

          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 122.9%;

          border: none;
          outline: none;

          color: white;
          border-radius: 100px;
          text-transform: uppercase;

          border: 1px solid $secondary_color;
          color: #848484;
          background: white;
          display: block;
          max-width: 110px;
          width: 100%;

          cursor: pointer;
        }

        input:checked + label {
          background: $secondary_color;
          color: #fff;
          transition: 0.1s;
        }

        &.extended {
          label {
            margin: 0 auto;
            max-width: fit-content !important;
            text-transform: inherit;
          }
        }
      }

      .extended {
        margin: 18px 0px 67px;

        @include mobileL {
          margin: 11px 0px 50px;
        }
      }
    }

    .step-seven {
      .seven-title {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        max-width: 748px;
        margin: auto;
        margin-top: 32px;
        color: #043f69;

        @include mobileML {
          margin-top: 80px;
        }

        h2 {
          font-family: "Forma DJR Banner", sans-serif;
          font-weight: 600;
          font-size: 38px;
          margin-bottom: 16px;
          letter-spacing: 0.02em;
          text-align: center;

          @include mobileL {
            font-size: 22px !important;
          }
        }
        p {
          font-family: "Roboto";
          font-weight: 300;
          font-size: 18px;
          line-height: 30px;
          width: 100%;
          text-align: center;

          @include mobileL {
            font-size: 14px !important  ;
          }
        }
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 665px;
      width: 100%;

      .title-container {
        margin-bottom: 1.5rem;

        &__wrapper {
          position: relative;

          > img {
            position: absolute;
            top: 22px;
            right: 12px;

            @include mobileL {
              left: 75px;
            }
          }
        }

        select {
          width: 100px;
        }
      }

      .extended {
        margin: 18px 0px 48px;

        @include mobileL {
          margin-bottom: 20px;
        }
      }

      .dob-container {
        > div {
          > select {
            width: 100%;
          }
        }
      }

      h3 {
        font-family: "Forma DJR Banner", sans-serif;
        color: $secondary_color;

        font-style: normal;
        font-weight: 600;
        font-size: 38px;
        line-height: 110%;
        letter-spacing: 0.02em;
        text-align: center;
        text-align: center;
        margin: 50px auto 50px;
      }

      > p:first-of-type {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;
        text-align: center;
        color: #043f69;
        width: 100%;
      }

      .address-container {
        margin-bottom: 10px !important;

        .termsWrapper {
          display: flex;
        }

        .terms-p {
          margin-top: 20px;
          font-family: "Roboto";
          font-style: italic;
          font-weight: 300;
          font-size: 14px;
          line-height: 20px;
          text-align: left;
          color: #043f69;

          a {
            color: #259cf4;
          }
        }

        > input,
        .address-row > input {
          &::placeholder {
            opacity: 0.5;
          }
        }
      }

      .first-row {
        gap: 11px;

        > input {
          margin-right: 0px !important;
        }
        @include mobileL {
          display: flex;
          flex-direction: column !important;
        }
      }

      .address-container,
      .personal-details-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-bottom: 7.7rem;

        input {
          margin-bottom: 1.5rem;
        }

        > div {
          position: relative;
          display: flex;
          justify-content: space-between;

          button {
            height: fit-content;
            width: 50%;

            margin-top: 0px !important;
          }
        }

        input {
          width: 100%;

          &:first-of-type {
            margin-right: 1.5rem;
          }
        }

        .address-container__lookup {
          width: 50%;
          margin-right: 1.5rem;
          input {
            max-width: 100%;
          }
        }
      }

      .dob-container {
        gap: 16px;
      }

      .input-dropdown {
        color: "gray";
        background: #eef1f1;
        border: 0.2px solid #03406d;
        border-radius: 4px;

        font-size: 1.6rem;
        line-height: 3rem;
        text-indent: 2rem;
        padding: 1rem 0;
        font-weight: 400;

        -webkit-appearance: none; /* Safari and Chrome */
        -moz-appearance: none; /* Firefox */
        appearance: none;

        text-indent: 40%;

        &.selected {
          color: black !important;
        }

        @include mobileL {
          text-indent: 30%;
        }

        &#title {
          text-indent: 20%;

          @include mobileL {
            text-indent: 15%;
          }
        }
      }

      .dob-text {
        p {
          font-family: "Roboto";
          font-weight: 300;
          font-size: 14px;
          margin: 0;
          margin-bottom: 5px;
          text-align: left;
        }
      }
      .dropdown-wrapper {
        position: relative;
        width: 33%;

        > img {
          position: absolute;
          right: 15px;
          top: 22px;
        }
      }

      .dropdown-results {
        margin: 0 !important;
        top: 62%;
        width: 100%;
        height: 180px;
        overflow-y: scroll;
        position: absolute;
        background: white;
        z-index: 10;
        border: 1px solid $secondary_color;
        border-radius: 10px;

        li {
          padding: 1rem;
          font-size: 12px;
          border-bottom: 0.5px solid grey;
          cursor: pointer;
        }
      }

      .error-container {
        background: #fde9e9;
        border: 1px solid #ff0000;
        border-radius: 10px;
        color: rgba(37, 20, 72, 0.4);
        display: flex;
        width: 100%;
        padding: 15px 0;
        margin-bottom: 38px;

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          max-width: 593px;
          margin: 0 !important;
          text-align: left;
          font-family: "roboto";

          @media (max-width: 520px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        img {
          margin-left: 10px;
          margin-right: 15px;
        }
      }

      .address-container-2 {
        display: flex;
        flex-direction: column;
        width: 100%;

        h4 {
          font-family: "Forma DJR Banner", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          line-height: 110%;
          letter-spacing: 0.02em;
          color: #043f69;
          margin-bottom: 30px;
          text-align: left;

          @include mobileL {
            font-size: 17px !important;
          }
        }

        .current-address-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-bottom: 50px;

          > input {
            margin-bottom: 25px;
          }
        }

        .previous-address-wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 50px;

          .search-row {
            display: flex;
            gap: 30px;
            margin-bottom: 30px;
            position: relative;
            > input {
              width: 227px;
            }

            @include mobileL {
              gap: 10px;
            }
          }

          > input {
            margin-bottom: 30px;
          }

          .bottom-row {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          .address-dropdown {
            margin: 0 !important;
            top: 130px;
            width: 100%;
            height: 180px;
            overflow-y: scroll;
            position: absolute;
            background: white;
            z-index: 10;
            border: 1px solid $secondary_color;
            border-radius: 10px;

            li {
              padding: 1rem;
              font-size: 12px;
              border-bottom: 0.5px solid grey;
              cursor: pointer;
            }
          }
        }

        .selected-address {
          color: #043f69;
          background: #eef1f1;
          border: 0.2px solid #03406d;
          border-radius: 4px;
          min-height: 49px;
          margin-bottom: 25px;

          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem !important;
          line-height: 3rem;

          text-indent: 2rem;

          padding: 1rem 0;

          &::placeholder {
            color: #043f69 !important;
            font-weight: 300;
          }

          @include mobileL {
            text-indent: 1.5rem;
          }

          @include mobileML {
            font-size: 14px !important;
          }

          p {
            font-weight: 400 !important;
            font-size: 1.6rem !important;
            line-height: 3rem !important;

            text-indent: 2rem;  
            margin-bottom: 0 !important;
          }
        }

        .year-select-wrapper {
          position: relative;
          width: 227px;

          > img {
            position: absolute;
            right: 15px;
            top: 22px;
          }

          .year-select {
            color: "gray";
            background: #eef1f1;
            border: 0.2px solid #03406d;
            border-radius: 4px;
            width: 100%;

            font-size: 1.6rem;
            line-height: 3rem;
            text-indent: 2rem;
            padding: 1rem 0;
            font-weight: 400;

            -webkit-appearance: none; /* Safari and Chrome */
            -moz-appearance: none; /* Firefox */
            appearance: none;
          }
        }
      }
    }

    button {
      margin: 0 auto;
    }
  }

  @include mobileL {
    p {
      font-size: 2.2rem !important;
      text-align: center;
      margin-bottom: 3rem;
      width: 100%;
    }

    .step-one {
      input {
        margin-bottom: 6rem;
      }
    }

    .step-four {
      > div {
        margin-bottom: 4rem;
      }
    }

    .step-seven {
      button {
        margin-top: 1rem;
      }
    }
    .step-seven {
      margin-top: -3rem;
      align-items: center;

      .personal-details-container {
        margin-bottom: 4rem;

        .contact-container {
          @include mobileL {
            flex-direction: column;
          }
        }
        > div {
          flex-direction: row;

          input {
            margin-right: 0;
            max-width: 100%;
          }
        }
      }

      .address-container {
        margin-bottom: 3rem;

        div:first-of-type {
          margin-bottom: 0.5rem;
        }

        div:not(:first-of-type) {
          flex-direction: column;

          input {
            margin-right: 0;
            max-width: 100%;
          }
        }
      }
    }
  }

  @include mobileML {
    p {
      font-style: normal;
      font-size: 20px !important;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #043f69;
      margin-bottom: 15px;
      width: 324px;
      margin: auto;
      margin-bottom: 29px;
    }
  }
}
