@import "../../../../styles/global/colors.scss";
@import "../../../../styles/global/devices.scss";

.howDoI-section {
  background: $tertiary_color;
  padding: 100px 0;
  color: white;

  h2 {
    text-align: center;
    font-size: 50px;
    font-family: "Forma DJR Banner", sans-serif;
  }

  button {
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }

  @include mobileL {
    h2 {
      font-size: 40px;
    }
  }

  @include mobileML {
    padding: 50px 0;

    h2 {
      font-size: 30px;
    }
  }

  .howDoI-content {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    li {
      position: relative;
      height: 220px;
      max-width: 500px;
      width: 100%;

      p:first-of-type {
        font-size: 200px;
        font-family: "Forma DJR Banner", sans-serif;
        opacity: 0.1;
        display: flex;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        font-style: normal;
        font-weight: 500;
      }

      p:last-of-type {
        width: 100%;
        position: absolute;
        font-style: normal;
        font-weight: 300;
        font-size: 22px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &:nth-of-type(1),
      &:nth-of-type(2),
      &:nth-of-type(3) {
        max-width: 304px;
      }

      &:nth-of-type(3) {
        p:last-of-type {
          width: 388px;
        }
      }
    }

    @include laptopL {
      li {
        &:nth-of-type(3) {
          p:last-of-type {
            max-width: 348px;
          }
        }
      }
    }
    @include laptop {
      li {
        p:last-of-type {
          max-width: 250px !important;
        }
      }
    }
    @include tabletL {
      li {
        p:first-of-type {
          font-size: 170px;
        }
        p:last-of-type {
          font-size: 18px;
        }
      }
    }
    @include mobileL {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      li {
        p:last-of-type {
          max-width: 100% !important;
        }
      }
    }
    @include mobileML {
      li {
        height: 145px;

        p:first-of-type {
          font-size: 130px;
        }

        p:last-of-type {
          top: 62%;
        }
      }
    }
  }
}
