@import "../../styles/global/devices.scss";

.section-sorry {
  background-color: #fff;
  background-image: url("../../assets/images/bg-white.png");
  background-size: cover;
  background-position: 0 0;
  padding: 200px 0;
  min-height: 80vh;

  @include mobileML {
    padding: 120px 0;
  }

  .container-sorry {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 960px;
    margin: 0 auto;

    h2 {
      font-family: "Forma DJR Banner", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 110%;

      text-align: center;
      letter-spacing: 0.02em;
      color: #043f69;
    }

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #043f69;
      margin: 20px 0 85px;

      &:first-of-type {
        margin: 20px 0 0px;
      }

      &:last-of-type {
        margin: 0px 0 85px;
      }
    }

    @include mobileML {
      h2 {
        font-size: 26px !important;
        line-height: 110%;
      }

      p {
        font-size: 14px !important;
        line-height: 20px;

        &:first-of-type {
          margin: 20px 0 0px;
        }

        &:last-of-type {
          margin: 20px 0 50px;
        }
      }
    }
  }
}
