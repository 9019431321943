@import "../../styles/global/devices.scss";

.footer {
  background: rgb(4, 71, 122);
  padding: 24px 0px 24px;

  .wrapper {
    font-size: 16px;
    color: #fff;
    font-weight: 300;

    .logo{
      width: 100px;
      height: 100px;
      margin-bottom: -20px;
      margin-top: -20px;

      path{
        fill: #fff;
      }
    }

    .body-footer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 50px 0px 70px;

      & > p {
        line-height: 24px;
        width: 60%;
      }
      & > ul {
        width: 40%;
        list-style: none;
        text-decoration: underline;
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        li:not(&:last-child) {
          margin-bottom: 5px;
        }
        a {
          font-size: 16px;
          color: #fff;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
    .bottom-footer {
      display: flex;
      justify-content: space-between;
      align-self: flex-end;
      font-size: 12px;
      margin-top: 75px;
    }
  }

  @include mobileL {
    padding: 34px 0 15px;

    .wrapper {
      .body-footer {
        flex-direction: column;
        align-items: start;
        padding: 20px 0 0px;

        > p {
          width: 100%;
          font-size: 13px;
        }

        > ul {
          margin: 15px 0 50px;
          align-items: flex-start;
          font-size: 13px;
        }
      }
      .bottom-footer {
        flex-direction: column-reverse;

        > a {
          position: relative;

          svg {
            position: absolute;
            right: 0;
            top: -78px;
          }
        }

        p {
          text-align: center;
        }
      }
    }
  }

  @include mobileML {
    > div > div:nth-of-type(2) p {
      font-size: 8px;
    }
  }
}
