@import "../../../../styles/global/colors.scss";
@import "../../../../styles/global/devices.scss";

.whatis-section {
  padding: 100px 0;
  background: url("../../../../assets/images/bg-white.png");
  background-size: cover;
  background-position: center;

  .whatis-container {
    display: flex;
    justify-content: space-between;
    color: $secondary_color;

    h2 {
      font-size: 52px;
      font-weight: 700;
      font-family: "Forma DJR Banner", sans-serif;
    }

    > div {
      max-width: 40%;

      &:first-of-type {
        h2 {
          margin-bottom: 30px;
        }

        p {
          font-size: 24px;
          font-weight: 300;
          line-height: 42px;
        }
      }

      &:last-of-type {
        ul li,
        p {
          font-size: 20px;
          line-height: 35px;
          font-weight: 300;

          span {
            font-weight: 500;
            font-size: 21px;
            margin-bottom: 10px;
            display: block;
          }
        }
      }

      ul {
        list-style: none;
        margin-left: 20px;
        margin-bottom: 30px;
        li {
          position: relative;
        }

        li:before {
          content: ".";
          font-size: 50px;
          position: absolute;
          top: -15px;
          left: -20px;
        }
      }
    }

    @include laptop {
      > div {
        max-width: 48%;
      }
    }

    @include tablet {
      flex-direction: column;

      > div {
        max-width: 100%;

        &:first-of-type {
          margin-bottom: 40px;
        }
      }
    }

    @include mobileL {
      h2 {
        font-size: 40px;
      }
    }

    @include mobileML {
      h2 {
        font-size: 30px;
      }

      > div {
        &:first-of-type {
          p {
            font-size: 18px;
            line-height: 29px;
          }
        }

        &:last-of-type {
          > ul > li {
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }

  @include mobileML {
    padding: 50px 0;
  }
}
