@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap');

@font-face {
  font-family: 'Forma DJR Banner', sans-serif;
  font-weight: 400;
  src: url('../../assets/fonts/FormaDJRBanner-Regular-Testing.ttf') format('ttf'),
}

@font-face {
  font-family: 'Forma DJR Banner', sans-serif;
  font-weight: 700;
  src: url('../../assets/fonts/FormaDJRBanner-Bold-Testing.ttf') format('ttf'),
}

@font-face {
  font-family: 'Forma DJR Banner', sans-serif;
  font-weight: 300;
  src: url('../../assets/fonts/FormaDJRBanner-Light-Testing.ttf') format('ttf'),
}