@import "../../styles/global/devices.scss";

.section-form {
  min-height: 80vh;
  padding: 100px 0;
  background: url("../../assets/images/bg-white.png");
  background-size: cover;
  background-position: 0 0;

  @include mobileML {
    padding: 50px 0 100px;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .form-progress {
      padding: 30px 0 100px;

      @include mobileL {
        padding-bottom: 80px;
      }
    }

    .bottom-banner {
      margin-top: 40px;
      p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 22px;
        text-align: center;
        margin-bottom: 0px;
        color: #043f69;

        @include mobileL {
          font-size: 20px;
        }
      }
    }
  }
}
