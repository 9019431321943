@import "../../../../styles/global/colors.scss";

.progressBar{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  > div {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 110%;

    color: #ffffff;
    width: 100%;
    height: 37px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
    border: 1px solid white;

    &.active {
      background: #043f69;
      transition: 0.2s;
    }

    &.inactive {
      background: $primary_color;
    }

    &:nth-of-type(1) {
      border-radius: 50px;
    }

    &:nth-of-type(1) {
      z-index: 10;
    }

    &:nth-of-type(2) {
      z-index: 9;
      margin-left: -15px;
    }

    &:nth-of-type(3) {
      z-index: 8;
      margin-left: -15px;
    }

    &:nth-of-type(4) {
      z-index: 7;
      margin-left: -15px;
    }
  }
}