@import "../../styles/global/devices.scss";
@import "../../styles/global/colors.scss";

.esign-container {
  margin: auto;
  padding: 120px 100px;
  text-align: center;
  background-color: #fff;
  background-image: url("../../assets/images/bg-white.png");
  background-size: cover;
  background-position: 0 0;

  .esign-inner {
    display: flex;
    flex-direction: column;
    max-width: 900px;
    width: 100%;
    margin: auto;

    > button {
      width: 284px;
    }

    .title-esign {
      color: #043f69;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 47px;

      @include mobileL {
        margin-bottom: 38px;
      }

      > h2 {
        font-family: "Forma DJR Banner", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 38px;
        line-height: 110%;
        letter-spacing: 0.02em;
        margin-bottom: 12px;

        @include mobileL {
          font-size: 22px !important;
        }
      }

      > p {
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 30px;

        @include mobileL {
          font-size: 14px !important;
        }
      }
    }

    .signature-wrapper {
      position: relative;
      margin: 0 auto;
      width: 100%;
      max-width: 940px;
      margin-bottom: 50px;

      @include mobileL {
        margin-bottom: 60px;
      }

      > p {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        color: #ffffff;
        background: #04477a;
        width: 100%;
        padding: 10px 20px;
        cursor: pointer;

        position: absolute;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        left: 50%;
        transform: translateX(-50%);

        @include mobileL {
          padding: 10px 20px;
          font-size: 11px !important;
          line-height: 15px;
        }
      }

      .background-texts {
        font-family: "Optima";
        position: absolute;
        font-size: 80px;
        right: 50%;
        top: 30%;
        white-space: nowrap;
        user-select: none;
        -webkit-user-select: none;
        pointer-events: none;
        color: #c4c4c4;

        p {
          position: absolute;
          padding: 10px 0;
          transform: translateX(-50%);
          white-space: nowrap;
          user-select: none;
          -webkit-user-select: none;
          pointer-events: none;
        }

        @include mobileL {
          font-size: 57px !important;
          top: 40%;
        }
      }

      .signature-canvas {
        border: 0.2px solid #03406d;
        width: 100%;
        max-width: 940px;
        height: 280px;
        border-radius: 10px;
        background: #eef1f1;
      }
    }
  }

  .confirmation-ctn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 40px;
    position: relative;

    @include mobileL {
      margin-bottom: 0px;
    }

    > div {
      margin-top: 5px;

      > input {
        cursor: pointer;
        width: 22px;
        height: 22px;
        border-radius: 2px;
        background-color: white;
        border: 1px solid #bfbfbf;

        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;

        &::after {
          content: "";
          display: inline-block;
          width: 22px;
          height: 22px;
          border: 1px solid #bfbfbf;
          background: white;
          cursor: pointer;
          border-radius: 2px;
          position: absolute;
          top: 5px;
          left: 0;
        }

        &:checked {
          &::after {
            background-size: contain;
            background-repeat: no-repeat;
            background: white;
          }
          &::before {
            content: "";
            display: inline-block;
            width: 18px;
            height: 16px;
            position: absolute;
            background: url("../../assets/icons/single-tick.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            border: none;
            top: 7px;
            left: 2px;
            z-index: 50;
            border-radius: 5px;
          }
        }
      }
    }

    > label,
    a {
      font-family: "roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      font-style: italic;
      line-height: 19px;
      text-align: left;
      color: #787878;
      padding-right: 5px;

      @include mobileL {
        font-size: 11px;
      }

      a,
      a:active {
        text-decoration: underline, italic;
      }
    }
  }

  @include mobileL {
    padding: 100px 24px;
  }
}
