@import "../../styles/global/devices.scss";
@import "../../styles/global/colors.scss";

.section-thankyou {
  background-color: #fff;
  background-image: "../../assets/images/bg-white.png";
  background-size: cover;
  background-position: 0 0;
  padding: 200px 0;
  min-height: 80vh;

  @include mobileML {
    padding: 120px 0;
  }

  .container-thankyou {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    max-width: 750px;
    margin: 0 auto;

    h2 {
      font-family: "Forma DJR Banner", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 38px;
      line-height: 110%;
      margin-bottom: 16px;
      text-align: center;
      letter-spacing: 0.02em;
      color: #043f69;
    }

    p {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 30px;
      text-align: center;
      color: #043f69;
      display: inline-block;

      &:first-of-type {
        margin: 20px 0 0px;
      }

      &:last-of-type {
        margin: 0px 0 40px;
      }
    }

    @include mobileML {
      h2 {
        font-size: 26px !important;
        line-height: 110%;
      }

      p {
        font-size: 14px !important;
        line-height: 20px;
        padding: 0 20px;

        &:first-of-type {
          margin: 20px 0 0px;
        }

        &:last-of-type {
          margin: 20px 0 34px;
        }
      }
    }
  }

  // .second-title-thankyou {
  //   color: #043f69;
  //   text-align: center;
  //   h3 {
  //     margin-top: 21px;
  //     font-size: 30px;
  //     font-weight: 700;
  //   }

  //   h4 {
  //     font-size: 24px;
  //     font-weight: 700;
  //     margin-top: 20px;
  //   }

  //   p {
  //     font-size: 24px;
  //     font-weight: 400;
  //     margin-top: 25px;
  //   }
  // }

  // .redirect-container {
  //   display: flex;
  //   flex-direction: row;
  //   width: 100%;
  //   max-width: 900px;
  //   margin: 0 auto;
  //   justify-content: space-between;
  //   margin-top: 60px;

  //   & > div {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     padding: 20px 10px 10px 10px;
  //     border-radius: 50px;

  //     background: #f9f9f9;
  //     max-width: 48%;
  //     width: 100%;

  //     h3 {
  //       color: #043f69;
  //       font-family: "Roboto";
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 18px;
  //       line-height: 32px;
  //       margin: 15px 0;
  //       text-align: center;

  //       @media (max-width: 768px) {
  //         line-height: 24px;
  //       }
  //     }

  //     button {
  //       border: 1px solid #ffffff;
  //       border-radius: 43px;
  //       padding: 22px 15px;
  //       width: 100%;
  //       font-family: "Roboto";
  //       font-style: normal;
  //       font-weight: 500;
  //       font-size: 24px;
  //       line-height: 28px;
  //       color: white;
  //       cursor: pointer;

  //       @media (max-width: 768px) {
  //         font-size: 18px !important;
  //         padding: 15px 10px;
  //       }
  //     }
  //   }

  //   & .container__left {
  //     margin: auto;
  //     button {
  //       background: #42a3d8;
  //     }
  //   }

  //   & .container__right {
  //     margin: auto;

  //     button {
  //       background: #30cf94;
  //     }
  //   }

  //   @include tablet {
  //     flex-direction: column;
  //     align-items: center;
  //     justify-content: center;

  //     & > div {
  //       max-width: 80%;

  //       &:first-of-type {
  //         margin-bottom: 40px;
  //       }
  //     }
  //   }
  // }
}
