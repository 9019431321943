@import "../../../../styles/global/devices.scss";

.faqs-section {
  padding: 100px 0;

  background: url("../../../../assets/images/bg-white.png");
  background-size: cover;
  background-position: center;

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 50px;
    line-height: 120%;
    color: #04477a;
    font-family: "Forma DJR Banner", sans-serif;
  }

  @include mobileL {
    h2 {
      font-size: 40px;
    }
  }

  @include mobileML {
    padding: 50px 0;

    h2 {
      font-size: 30px;
    }
  }

  .faqs-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .container__left {
      width: 25%;
    }

    .container__right {
      width: 60%;

      > ul {
        list-style: none;

        > li {
          padding: 24px 0;

          &:not(:last-of-type) {
            border-bottom: 1px solid #04477a76;
          }

          > div {
            &:first-of-type {
              display: flex;
              justify-content: flex-start;
              flex-direction: row;
              align-items: center;

              img {
                margin-right: 20px;
              }
              h5 {
                font-style: normal;
                font-weight: 600;
                font-size: 24px;
                line-height: 42px;
                font-family: "Forma DJR Banner", sans-serif;

                color: #04477a;
              }
            }

            &:last-of-type {
              margin-left: 35px;
              font-style: normal;
              font-weight: 300;
              font-size: 18px;
              line-height: 28px;
              color: #04477a;

              button {
                background: none;
                outline: none;
                border: none;
                border-bottom: 1px solid #04477a;
                font-weight: 500;
                font-size: 18px;
                line-height: 28px;
                color: #04477a;
                cursor: pointer;
              }

              ul {
                margin-left: 20px;
                list-style: disc;
              }
            }
          }
        }
      }
    }

    @include tabletL {
      flex-direction: column;
      justify-content: center;

      .container__left,
      .container__right {
        width: 100%;
      }
    }

    @include mobileML {
    .container__right {
      > ul {
        > li {
          > div:first-of-type {
            > h5 {
              font-size: 16px;
              line-height: 26px;
              color: #04477a;
            }
          }
          > div:last-of-type,
          > div:last-of-type > p > button {
            font-style: normal;
            font-size: 14px;
            line-height: 22px;
            color: #04477a;
          }
        }
      }
    }
  }
  }
}
