@import "../../../../styles/global/colors.scss";
@import "../../../../styles/global/devices.scss";

.section-benefits {
  background: $secondary_color;
  padding: 70px;

  ul {
    display: flex;
    justify-content: space-between;
    list-style: none;

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: white;
      max-width: 195px;

      img {
        margin-bottom: 20px;
      }

      p {
        font-size: 22px;
        text-align: center;
        font-weight: 300;
      }
    }
  }

  @include laptop {
    padding: 70px 0;
  }
  @include tablet {
    > div {
      > ul {
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 0 auto;
        max-width: fit-content;

        li {
          max-width: 100%;
          margin-bottom: 30px;
          flex-direction: row;
          align-items: center;

          p {
            text-align: left;
          }

          img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
          }
        }
      }
    }
  }
  @include mobileML {
    padding: 50px 0;

    > div {
      > ul {
        li {
          align-items: flex-start;
          p {
            font-size: 18px;
          }
          img {
            width: 30px;
            height: 30px;
            margin-bottom: 0;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
